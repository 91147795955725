import React from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Divider } from "@mui/material";

import MyBuys from "../myBuys";

import Header from "./Header";
import PaymentDetail from "./payments";
import { classes, HeaderSection } from "./styled";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const TransactionListPerMandi = () => {
  const { outStandingCardBaground } = classes();
  const { state } = useLocation();

  const {
    overdue_amount,
    credit_limit,
    outstanding_amount,
    unit,
    unit_status,
    drawing_power,
  } = state;
  const unitStatus = unit_status === "Active";

  return (
    <StyledContainer>
      <Header isMandiActive={unitStatus} mandiName={unit} />
      <Divider />
      <HeaderSection elevation={1} className={` ${outStandingCardBaground}`}>
        <PaymentDetail
          overdueAmount={+overdue_amount}
          outStandingAmount={+outstanding_amount}
          totalCreditLimit={+credit_limit}
          drawingPower={+drawing_power}
          isDivider={false}
          isMandiActive={unitStatus}
          mandiName={unit}
        />
      </HeaderSection>
      <MyBuys source="MyBuysMandiFilteredPage" />
    </StyledContainer>
  );
};

export default TransactionListPerMandi;
