import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import useMoEngage from "hooks/useMoEngage";
import { consentSend } from "services/termsAndCondition";
import { PAGE_EVENTS } from "utils/events";
import { getCustomerData, savePrivacyPolicy } from "utils/localStorage";
import { updatePolicyData } from "utils/networkUtils";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";

import { Header } from "components/BackPagehandler/styled";

import ConfirmationPopUp from "./ConfirmationPopUp";
import { classes } from "./styled";
import TermsAndConditionContent from "./TermsAndConditionContent";

const TermsAndConditions = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [ip, setIp] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [message, setMessage] = useState("");
  const [warningPopup, setWarningPopup] = useState(true);

  const [open, setOpen] = useState(false);

  const tracker = useMoEngage();
  const customerData = getCustomerData("customerData");

  const { me } = useSelector((state) => state.app);
  const { actionStyle, buttonStyle, formControl, header, headerText } =
    classes();
  const { colors } = useTheme();

  useEffect(() => {
    // Get IP Address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    let id = localStorage.getItem("device-id");
    if (!id) {
      id = uuidv4();
      localStorage.setItem("device-id", id);
    }
    setDeviceId(id);
  }, []);

  const requestGeolocation = () => {
    navigator.permissions
      ?.query({ name: "geolocation" })
      ?.then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
              setOpen(false);
            },
            (error) => {
              console.error("Error getting location:", error);
              setOpen(true);
            }
          );
        } else if (result.state === "denied") {
          setMessage(
            "Location access has been denied. Please enable location access in your browser settings."
          );
          setWarningPopup(true);
        }
      })
      .catch((error) =>
        console.error("Error checking geolocation permissions:", error)
      );
  };

  const requestMobileGeolocation = async () => {
    try {
      const permissionStatus = await Geolocation.checkPermissions();

      if (permissionStatus.location === "granted") {
        const position = await Geolocation.getCurrentPosition();
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setOpen(false);
      } else if (permissionStatus.location === "prompt") {
        const permissionRequest = await Geolocation.requestPermissions();
        if (permissionRequest.location === "granted") {
          const position = await Geolocation.getCurrentPosition();
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setOpen(false);
        } else {
          setMessage(
            "Location access has been denied. Please enable location access in your app settings."
          );
          setWarningPopup(true);
        }
      } else if (permissionStatus.location === "denied") {
        setMessage(
          "Location access has been denied. Please enable location access in your app settings."
        );
        setWarningPopup(true);
      }
    } catch (error) {
      console.error("Error getting location:", error);
      setMessage(
        "Location access has been denied. Please enable location access in your app settings."
      );
      setWarningPopup(true);
    }
  };

  useEffect(() => {
    // if (Capacitor.isNativePlatform) {
    //   requestMobileGeolocation();
    // } else {
    requestGeolocation();
    // }

    tracker(PAGE_EVENTS.AGREEMENT_PAGE_VIEW, {
      customer_name: me?.fullName,
      mobile_number: customerData?.phone_number,
      customer_id: me?.customerId,
    });
  }, []);

  const termsAgreedHandler = () => {
    savePrivacyPolicy("policyAccepted", true);
    consentSend({
      consent_for_id: me.customerId,
      verification_type: "app",
      verified_at: Date.now(),
      ip_address: ip,
      device_id: deviceId,
      latitude,
      longitude,
    });
    updatePolicyData(me);
  };

  const handleCheckboxChange = (event) => {
    if (!latitude || !longitude) {
      requestGeolocation();
      setWarningPopup(true);
      setIsChecked(false);
    } else {
      setIsChecked(event.target.checked);
    }
  };

  const warningPopupHandler = () => {
    setWarningPopup(false);
    setIsChecked(false);
  };

  const closePopup = () => {
    setOpen(false);
    if (!latitude || !longitude) {
      if (Capacitor.isNativePlatform) {
        requestMobileGeolocation();
      } else {
        requestGeolocation();
      }
    }
  };
  const policy = <TermsAndConditionContent customerName={me.fullName} />;

  return (
    <div>
      <Header colors={colors} className={header}>
        <Typography className={headerText}>Terms And Conditions</Typography>
      </Header>
      <DialogContent style={{ padding: "0" }}>{policy}</DialogContent>
      <DialogActions className={actionStyle}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBox />}
              style={{ padding: "0 9px 9px 9px" }}
            />
          }
          className={formControl}
          label={
            <>
              <div>
                By clicking 'I Accept,' I confirm that I have read, understood,
                and agreed to the Terms & Conditions of the Agreement, along
                with the accompanying Privacy Policy, and I consent to abide by
                them.
              </div>
              <div style={{ marginTop: "1rem" }}>
                'I Accept' पर क्लिक करके, मैं पुष्टि करता/करती हूं कि मैंने
                अनुबंध की नियम और शर्तें और साथ ही साथ संबंधित गोपनीयता नीति को
                पढ़ लिया है, समझ लिया है और उन्हें स्वीकार करता/करती हूं।
              </div>
            </>
          }
        />
        <div className={buttonStyle}>
          <Button
            onClick={termsAgreedHandler}
            disabled={!isChecked}
            style={{
              backgroundColor: isChecked ? colors.primary : "gray",
              color: "white",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
            className={buttonStyle}
          >
            I Accept
          </Button>
        </div>
      </DialogActions>
      {(!latitude || !longitude) && (
        <ConfirmationPopUp
          open={open || (warningPopup && message)}
          content={
            open
              ? "Location access is required. Please go to settings and grant location permission."
              : message
          }
          primaryBtnHandler={open ? closePopup : warningPopupHandler}
          primaryBtntext="Okay"
        />
      )}
    </div>
  );
};

export default TermsAndConditions;
