import { mandiService, velynkService } from "./base";

export const getAuctionDetails = (params) => {
  return mandiService.get(`/auction_lot`, {
    params,
  });
};

export const updateReviewDetails = (sale_order_id, params) =>
  velynkService.put(`/sale_orders/${sale_order_id}/update_feedback`, params);

export const getReviewDetails = (sale_order_id, params) =>
  velynkService.get(
    `/sale_orders/${sale_order_id}/get_sale_order.json`,
    params
  );

export const sendAcknowledgmentOtp = (params) => {
  return mandiService.post(`/saleorder/sendacknowledgementotp`, params);
};

export const verifyOtp = (params, lotIds) => {
  return mandiService.post(`/saleorder/verifyacknowledgementotp`, {
    auction_lots_with_bills: {
      ids: lotIds,
    },
    verify_otp_req: params,
  });
};

export const getCustomerBill = (params) => {
  const URL = {
    pathname: `${process.env.REACT_APP_MANDI_URL}customerbill.pdf?`,
    search: new URLSearchParams(params).toString(),
  };

  const fullUrl = URL.pathname + URL.search;
  return fullUrl;
};
