import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useImageAndColor } from "hooks/useImageAndColor";
import useMoEngage from "hooks/useMoEngage";
import { translate } from "locale/formatter";
import { isEmpty } from "lodash";
import { getAuctionDetails, getCustomerBill } from "services/myDetails";
import { path } from "utils/const";
import { currency } from "utils/currencyFormatter";
import { formatDate } from "utils/dateUtils";
import { MY_BUYS_CARD_DETAILS_PAGE_EVENTS } from "utils/events";

import BidderInfoCard from "components/BidderInfoCard";
import DottedDivider from "components/Divider/DottedDivider";
import Text from "components/Text";
import { TEXT_TYPES } from "components/Text/const";

import { PurchaseCardWrapper } from "../dashboard/styled";
import { useStyles } from "../styled";

const PurchaseCard = ({ data }) => {
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { imageTag, bgColor } = useImageAndColor(
    data?.mandi_product_categories
  );
  const classes = useStyles();
  const tracker = useMoEngage();

  const MandiName = styled.div(({ colors }) => ({
    padding: "0.25rem",
    borderRadius: "2px",
    background: bgColor,
    boxShadow: `0px 1px 1px ${colors.silver}`,
  }));

  const styles = {
    button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
      border: "0.96px solid #008767",
      borderRadius: "4px",
      backgroundColor: "#E8F5E9",
      color: "#008767",
      fontSize: "9px",
      fontWeight: "500",
      cursor: "default",
      marginTop: "4px",
    },
    checkIcon: {
      marginLeft: "4px",
      fontSize: "16px",
      color: "#008767",
    },
  };

  if (isEmpty(data)) return <></>;
  const {
    bill_number,
    bill_number_prefix,
    sale_order_id,
    customer_id,
    mandi_id,
    mandi_app_name,
    mandi_name,
    auction_date,
    total_value,
    auction_date_ts,
  } = data;

  const handleClick = () => {
    getAuctionDetails({
      mandi_id,
      customer_view: true,
      auction_date: auction_date_ts,
      customer_id,
      bill_number,
      sale_order_id,
    }).then(({ responseData }) => {
      const customerDetails = responseData[customer_id];
      const lotIds = customerDetails?.lots_data?.map((item) => item.id) || [];

      const billParams = {
        mandiId: mandi_id,
        auctionDate: auction_date_ts,
        customerId: customer_id,
        customer_view: true,
        isCustomerBill: true,
        auction_lot_ids: lotIds,
        isSignedBill: false,
      };

      navigate(path.acknowledgementPage, {
        state: {
          billNumberPrefix: bill_number_prefix,
          billNumber: bill_number.toString(),
          lotIds,
          pdfUrl: getCustomerBill(billParams),
        },
      });
    });
  };

  return (
    <PurchaseCardWrapper
      colors={colors}
      style={!data.acknowledged ? { border: "1.02px solid #E67621" } : {}}
    >
      <Grid container className="flex p-3">
        <Grid item xs={3}>
          {imageTag}
        </Grid>
        <Grid item xs={9} className="flex flex-column justify-content-end pl-2">
          <div className="flex justify-between">
            <div className="flex flex-column">
              <div>
                <MandiName colors={colors}>
                  <Text size="10px">{mandi_app_name || mandi_name}</Text>
                </MandiName>
              </div>
              <div>
                <Text textType={TEXT_TYPES.SECONDARY} size="14px">
                  {translate("total_value")}
                </Text>
              </div>
              <div>
                <Text size="18px">{currency(total_value)}</Text>
              </div>
            </div>
            <div className="flex flex-column">
              <Text size="10px">{formatDate(auction_date)}</Text>
              <>
                {data.acknowledged ? (
                  <div style={styles.button}>
                    {translate("signed")}
                    <CheckCircleIcon style={styles.checkIcon} />
                  </div>
                ) : (
                  <Button
                    className={classes.eSigCardButton}
                    onClick={() => {
                      handleClick();
                      tracker(
                        MY_BUYS_CARD_DETAILS_PAGE_EVENTS.ESIGN_BUTTON_CLICK
                      );
                      tracker(
                        MY_BUYS_CARD_DETAILS_PAGE_EVENTS.ESIGN_ACKNOWLEDGEMENT_PAGE_VIEWED
                      );
                    }}
                  >
                    {translate("esign")}
                  </Button>
                )}
              </>
            </div>
          </div>
        </Grid>
      </Grid>
      <DottedDivider />
      {/* Stats Info Card */}
      <BidderInfoCard data={data} />
    </PurchaseCardWrapper>
  );
};

export default PurchaseCard;
