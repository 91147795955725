export const REGEX_DIGIT = new RegExp(/^\d+$/);

export const ALPHA_NUMERIC = new RegExp(/^[a-zA-Z0-9]$/);

export const EVENT_NAMES = {
  BACKSPACE: "Backspace",
  ARROW_RIGHT: "ArrowRight",
  ARROW_LEFT: "ArrowLeft",
  ARROW_DOWN: "ArrowDown",
  ARROW_UP: "ArrowUp",
};

export const INPUT_TYPE = {
  ALPHA_NUMERIC: "alpha_numeric",
  NUMERIC: "numeric",
};
