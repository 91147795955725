import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";
import { currency } from "utils/currencyFormatter";

import Text from "components/Text";

import { BorderLinearProgress, classes, CreditProgressSection } from "./styled";

const ProgressBar = ({
  totalCreditLimit = 0,
  utilizedCreditLimit = 0,
  drawingPower = 0,
}) => {
  const [progress, setProgress] = useState(0);

  const { colors } = useTheme();
  const { creditLimit, fontNoto, fontSize8, creditPercentage } =
    classes(progress);

  const calcCreditLimit = () => {
    const creditUtilization = utilizedCreditLimit / drawingPower;

    return Number.isFinite(creditUtilization) &&
      !Number.isNaN(creditUtilization)
      ? Math.max(0, creditUtilization * 100)
      : 0;
  };

  useEffect(() => {
    const percentage = calcCreditLimit();
    setProgress(percentage);
    if (percentage > 100) {
      setProgress(100);
    }
  }, [drawingPower, utilizedCreditLimit]);

  const drawingPowerAmount =
    drawingPower.toFixed(2) > 0
      ? currency(drawingPower, "", true, false)
      : "NA";

  const creditLimitPercentage =
    drawingPower.toFixed(2) > 0 ? `${Math.round(calcCreditLimit())}%` : "";

  return (
    <CreditProgressSection>
      <Box className="w-[100%]">
        <Box>
          <Box>
            <Text component="p" className={creditLimit}>
              <span className={fontNoto}>{translate("credit_limit")}: </span>
              <span className={fontSize8}>{drawingPowerAmount}</span>
            </Text>
            {drawingPower.toFixed(2) > 0 && (
              <BorderLinearProgress
                variant="determinate"
                value={progress}
                colors={colors}
              />
            )}
            <Text
              component="p"
              textType="secondary"
              className={creditPercentage}
            >
              {creditLimitPercentage}
            </Text>
          </Box>
        </Box>
      </Box>
    </CreditProgressSection>
  );
};

export default ProgressBar;
