import { getUserData } from "./localStorage";

export const LOGIN_EVENTS = {
  LOGIN_FORM_SHOWN: "LoginFormShown",
  LOGIN_PAGE_ACTION: "LoginPageAction",
  OTP_PAGE_ACTION: "OtpPageAction",
  OTP_ENTERED: "OptEntered",
  LOGIN_SUCCESSFUL: "LoginSuccessful",
};

export const HOME_SCREEN_EVENTS = {
  HOME_SCREEN_VIEWED: "HomeScreenViewed",
  HOME_BUTTON_CLICK: "HomeButtonClick",
  MY_BOOKS_BUTTON_CLICK: "MyBooksButtonClick",
  PROFILE_ICON_CLICK: "ProfileIconClick",
  LOGOUT_CLICK: "LogoutClick",
  MY_TRANSACTION_ICON_CLICK: "MyTransactionsIconClick",
  AUCTION_CARD_VIEWED: "AuctionCardViewed",
  CATALOG_BUTTON_CLICK: "CatalogButtonClick",
  ENTER_AUCTION_BUTTON_CLICK: "EnterAuctionButtonClick",
  MY_BUYS_VIEW_ALL_CLICK: "MyBuysViewAllClick",
  MY_BUYS_HORIZONTAL_SCROLL: "MyBuysHorizontalScroll",
  HOME_VERTICAL_SCROLL: "HomeVerticalScroll",
  NETWORK_STRENGTH_CLICK: "NetworkStrengthClick",
};

export const MY_BUYS_CARD_DETAILS_PAGE_EVENTS = {
  MY_BUYS_CARD_DETAILS_VIEWED: "MyBuysCardDetailsViewed",
  MY_BUYS_CARD_DETAILS_PAGE_SCROLL: "MyBuysCardDetailsPageScroll",
  DOWNLOAD_INVOICE: "DownloadInvoice",
  SIGNED_INVOICE: "SignedInvoice",
  ESIGN_BUTTON_CLICK: "E-signButtonClick",
  ESIGN_ACKNOWLEDGEMENT_PAGE_VIEWED: "E-signAcknowledgementPageViewed",
};

export const MY_TRANSACTION_PAGE_EVENTS = {
  MY_TRANSACTION_PAGE_VIEWED: "MyTransactionPageViewed",
};

export const MY_BUYS_LISTING_PAGE_EVENTS = {
  MY_BUYS_LISTING_VIEWED: "MyBuysListingViewed",
  MY_BUYS_LISTING_DATE_FILTER_CLICK: "MyBuysListingDateFilterClick",
  MY_BUYS_LISTING_DATE_FILTER_APPLY: "MyBuysListingDateFilterApply",
};

export const MY_PAYMENTS_LISTING_PAGE_EVENTS = {
  MY_PAYMENTS_LISTING_VIEWED: "MyPaymentsListingViewed",
  MY_PAYMENTS_VALUE_HYPER_LINK_CLICK: "MyPaymentsValueHyperlinkClick",
  LEDGER_DOWNLOAD: "ledger_download",
};

export const MY_BOOKS_EVENTS = {
  MANDI_BOOKS_CARD_VIEWED: "MandiBooksCardViewed",
  MY_BOOKS_VERTICAL_SCROLL: "MyBooksVerticalScroll",
  PAY_NOW_BUTTON_CLICK: "PayNowButtonClick",
};

export const PAGE_EVENTS = {
  MANDI_FILTERED: "MandiFilteredPage",
  MY_TRANSACTION: "MyTransactionsPage",
  HOME: "FromHomePage",
  AGREEMENT_PAGE_VIEW: "AgreementPageView",
};

export const ORDER_REVIEW_EVENT = {
  RATING_CLICK: "rating_click",
  RATING_MORE_CLICK: "rating_more_click",
  RATING_CONFIRM_CLICK: "rating_confirm_click",
};

export const ESIGN_ACKNOWLEDGEMENT_EVENTS = {
  CONFIRM_CLICK: "ConfirmClick",
  CANCEL_CLICK: "CancelClick",
  OTP_RESEND_CLICK: "OtpResendClick",
  ESIGNED_SUCCESSFUL: "E-signedSuccessful",
};

export const getEventParams = () => {
  const { id, name } = getUserData() || {};

  return {
    customer_id: id,
    timestamp: String(new Date()),
    customer_name: name,
  };
};
