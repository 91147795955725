import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Card, Typography } from "@mui/material";
import { translate } from "locale/formatter";
import { colors } from "styles/AtomicStyles";
import { removeFruitX } from "utils";
import { setMandiId } from "utils/localStorage";

import AppButton from "components/Button";
import Text from "components/Text";

import PaymentDetail from "./payments";
import { CardHeader, classes, OnlineIndicator } from "./styled";

const TransactionCard = ({ key, CardItem }) => {
  const [activeMandiColor, setActiveMandiColor] = useState("");

  const navigate = useNavigate();
  const {
    activeMandi,
    inActiveMandi,
    lightText,
    mandiName,
    cardBaground,
    viewKhataButton,
    logoImage,
    viewKhataIcon,
  } = classes();
  const {
    mandi_id = "",
    overdue_amount = 0,
    credit_limit = 0,
    outstanding_amount = 0,
    drawing_power = 0,
    unit,
    unit_logo,
    unit_status,
    mandi_credit_unit_id,
  } = CardItem;

  const unitStatus = unit_status === "Active";
  const navigateToRoute = (pathname) => {
    navigate(`/bidder-transactions/${pathname}/${mandi_credit_unit_id}`, {
      state: { ...CardItem },
    });
    setMandiId(mandi_id);
  };

  useEffect(() => {
    setActiveMandiColor(unitStatus ? "black" : "gray");
  }, [unitStatus]);

  return (
    <Card
      className={`m-[1.5rem] text-[${activeMandiColor}] ${cardBaground}`}
      key={key}
    >
      <CardHeader
        className={unitStatus ? activeMandi : inActiveMandi}
        onClick={() => {
          navigateToRoute("my-payments");
        }}
      >
        <div className="flex items-center">
          <OnlineIndicator isOnline={unitStatus} />
          <img
            alt="unit_logo"
            src={Object.values(unit_logo)}
            className={logoImage}
          />
          &nbsp;&nbsp;
          <Typography variant="h6" className={mandiName}>
            <b>{removeFruitX(unit)}</b>
          </Typography>
        </div>
      </CardHeader>

      <div className="pl-8 pt-8 pr-8">
        <PaymentDetail
          overdueAmount={+overdue_amount}
          outStandingAmount={+outstanding_amount}
          totalCreditLimit={+credit_limit}
          drawingPower={+drawing_power}
          isMandiActive={unitStatus}
          mandiName={unit}
          mandiCreditUnitId={mandi_credit_unit_id}
          navigateToRoute={navigateToRoute}
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "right" }}
        onClick={() => {
          navigateToRoute("my-payments");
        }}
      >
        <AppButton className={viewKhataButton}>
          <>
            <Text className={lightText}>{translate("view")}</Text>
            <Text
              className="ml-1"
              color={colors.secondaryLightGrey}
              style={{ fontSize: "9px" }}
            >
              {translate("khata")}
            </Text>
          </>
          <ArrowForwardIosIcon className={viewKhataIcon} />
        </AppButton>
      </div>
    </Card>
  );
};

export default TransactionCard;
