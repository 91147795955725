import { useState } from "react";
import { Grid } from "@mui/material";
import useTracker from "hooks/useTracker";
import { translate } from "locale/formatter";
import { colors } from "styles/AtomicStyles";
import { formatDecimalNumber } from "utils";
import { currency } from "utils/currencyFormatter";

import ImageIcons from "components/AppIcons/ImageIcons";
import Text from "components/Text";

import PayNowModal from "./payNowModal";
import ProgressBar from "./progressBar";
import { classes, PaymentBtn, PaymentContainer } from "./styled";

const PaymentDetail = ({
  overdueAmount = 0,
  outStandingAmount = 0,
  totalCreditLimit = 0,
  isDivider = true,
  isMandiActive,
  mandiName,
  mandiCreditUnitId = "",
  navigateToRoute,
  drawingPower = 0,
}) => {
  const [openPayNow, setOpenPayNow] = useState(false);
  const {
    flexColContainer,
    overdueText,
    outstandingText,
    outstandingAmt,
    centerAlignVertically,
    dividerGradient,
    outStandingAmountContainer,
    verticalLine,
    fontSize13,
    paymentButton,
    overdueIcon,
    fontSize11,
    perMandiCard,
    payContainer,
  } = classes();

  const trackUser = useTracker();

  const handlePayNowBtnClicked = () => {
    setOpenPayNow(true);
    trackUser("PayNowButtonClick");
  };

  return (
    <PaymentContainer>
      <Grid container className={payContainer}>
        <Grid item md={7} xs={7}>
          {/* overdueAmount */}
          <div className={`${centerAlignVertically} mb-3`}>
            <div className={flexColContainer}>
              <div className={flexColContainer}>
                <ImageIcons
                  name="OverdueTime"
                  alt="OverdueTime"
                  className={overdueIcon}
                />
                <Text className={overdueText}>{translate("overdue")}</Text>
              </div>
              <Text color={colors.lightRed}>
                <span className="mx-1 !text-sm relative bottom-1.5">
                  {translate("INR")}
                </span>
                <span className={fontSize13}>
                  {currency(
                    formatDecimalNumber(overdueAmount).integer,
                    "",
                    false,
                    false
                  )}
                  <Text
                    size="small"
                    color={colors.lightRed}
                    style={{ fontSize: "8px" }}
                  >
                    {formatDecimalNumber(overdueAmount).decimal?.length > 2
                      ? ".00"
                      : formatDecimalNumber(overdueAmount).decimal}
                  </Text>
                </span>
              </Text>
            </div>
          </div>

          {/* outstandingAmount */}
          <div className={centerAlignVertically}>
            <div
              className={isDivider ? outStandingAmountContainer : perMandiCard}
            >
              <Text className={outstandingText}>
                {translate("outstanding")}
              </Text>
              <Text color={colors.secondaryLightGrey}>
                <span className="mx-1 !text-xs relative bottom-1">
                  {translate("INR")}
                </span>
                <span className={`${outstandingAmt} ${fontSize11}`}>
                  {currency(
                    formatDecimalNumber(outStandingAmount).integer,
                    "",
                    false,
                    false
                  )}
                  <Text
                    color={colors.secondaryLightGrey}
                    style={{ fontSize: "6px" }}
                  >
                    {formatDecimalNumber(outStandingAmount).decimal?.length > 2
                      ? ".00"
                      : formatDecimalNumber(outStandingAmount).decimal}
                  </Text>
                </span>
              </Text>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          className="flex align-items-center justify-content-between"
        >
          <div className={verticalLine} />
          <div>
            <PaymentBtn
              colors={colors}
              className={paymentButton}
              onClick={handlePayNowBtnClicked}
            >
              {translate("pay_now")}
            </PaymentBtn>
          </div>
        </Grid>
      </Grid>
      {isDivider && <div className={dividerGradient} />}
      <div onClick={() => navigateToRoute("my-payments")} className="mt-3">
        {/* progressBar */}
        <ProgressBar
          totalCreditLimit={totalCreditLimit}
          utilizedCreditLimit={outStandingAmount}
          drawingPower={drawingPower}
        />
      </div>

      <PayNowModal
        open={openPayNow}
        setOpenPayNow={setOpenPayNow}
        isMandiActive={isMandiActive}
        mandiName={mandiName}
        mandiCreditUnitId={mandiCreditUnitId}
        overdueAmount={overdueAmount}
        outStandingAmount={outStandingAmount}
      />
    </PaymentContainer>
  );
};

export default PaymentDetail;
