import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { styled as ComponentStyle } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import AppButton from "components/Button";

export const StyledButton = styled(AppButton)`
  width: 100%;
  text-transform: capitalize;
  color: black;
  &:hover {
    background-color: #f0f0f0;
    color: #81a93e;
    cursor: pointer;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.25rem;
  position: absolute;
  background: white;
  width: 9rem;
  right: 2px;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 7px 0px #00000014;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

export const StatsCard = styled.div(({ colors }) => ({
  backgroundColor: colors.lightGray,
  textAlign: "center",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  justifyContent: "center",
}));

export const TransactionCard = styled.div(({ colors }) => ({
  padding: "16px",
  backgroundColor: colors.white,
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
}));

export const LotsCard = styled.div(({ colors }) => ({
  backgroundColor: colors.white,
  border: `1px solid ${colors.gray2}`,
  borderRadius: "12px",
  display: "flex",
  justifyContent: "space-between",
  padding: "16px",
  marginTop: "12px",
  gap: "60px",
}));

export const DateSection = styled.span(({ colors }) => ({
  background: colors.gray2,
  color: colors.primary,
  padding: "4px",
  fontSize: "12px",
}));

export const BillDetailsCard = styled.div(({ colors }) => ({
  padding: "16px",
  backgroundColor: colors.white,
  ">div:nth-of-type(2)": {
    border: `1px solid ${colors.gray2}`,
    borderRadius: "12px",
    ".section": {
      padding: "16px",
    },
  },
}));

export const ChargesSection = styled.div(({ colors }) => ({
  display: "flex",
  justifyContent: "space-between",
  fontSize: "12px",
  padding: "8px 16px",
  ">div:first-of-type": {
    color: colors.gray3,
  },
}));

export const PurchaseDate = styled.div(({ colors }) => ({
  padding: "1rem",
  display: "flex",
  gap: "0.25rem",
  flexDirection: "column",
  border: `1px solid ${colors.cement}`,
  borderRadius: "4px",
  width: "20rem",
}));

export const PaperComponent = ComponentStyle(Paper)(() => ({
  position: "fixed",
  bottom: 0,
  zIndex: 1,
  width: "100%",
  padding: "1rem",
  maxHeight: "20vh",
}));

export const TabButtonsStyle = ComponentStyle(Link)(({ pathname, path }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  color: path === pathname ? "#277c74" : "#6d9894",
  background: path === pathname ? "#dae9e7" : "#fff",
  minWidth: "45%",
  fontSize: "large",
  padding: "0.5rem",
  borderRadius: "0.5rem",
  gap: "5px",
  "&:hover": {
    color: path === pathname && "#277c74",
  },
  "@media (max-width: 360px)": {
    alignItems: "center",
  },
}));

export const classes = makeStyles(({ colors }) => ({
  myBuysWrapper: {
    position: "sticky",
    top: -2,
    background: colors.white,
    padding: "1.25rem",
    zIndex: 2,
  },
  totalOutstandingWrapper: {
    background: colors.lightGray,
    borderRadius: "0.375rem",
    padding: "1rem 0 0.5rem 0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0.625rem 0 0 0",
  },
  outstandingTextWrapper: {
    background: colors.white,
    padding: "0.25rem 1rem",
  },
  totalOutstandingText: {
    fontSize: "1rem !important",
    fontWeight: "600 !important",
  },
  outstandingAmount: {
    color: `${colors.red} !important`,
  },
  calendarDiv: {
    display: "flex",
    borderRadius: "0.25rem",
    border: `1.5px solid ${colors.smokeWhite}`,
    padding: "1rem",
    alignItems: "center",
    justifyContent: "space-between",
    background: colors.white,
    paddingLeft: "1.7rem",
  },
  purchaseDateText: {
    color: `${colors.zambezi} !important`,
    fontWeight: "600 !important",
  },
  selectedDateRange: {
    color: `${colors.black} !important`,
    fontWeight: "500 !important",
    marginLeft: "0.5rem",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    position: "sticky",
    zIndex: 999999,
    top: 0,
    background: colors.white,
    height: "10%",
  },
  outstandingContainer: {
    background: colors.lightBlue,
    display: "flex",
    borderRadius: "5px",
    padding: "17px",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
  },
  fontSize20: {
    fontSize: "20px !important",
  },
  fontSize12: {
    fontSize: "12px !important",
  },
  fontSize10: {
    fontSize: "10px !important",
  },
  fontSize11: {
    fontSize: "11px !important",
  },
  flexClass: {
    display: "flex",
    alignItems: "center",
  },
  fontSize14: {
    fontSize: "14px !important",
  },
  fontSize22: {
    fontSize: "22px !important",
  },
  fontSize16: {
    fontSize: "16px !important",
  },
  marginLeft: {
    marginRight: "10px",
  },
  detailsClass: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    fontWeight: "bold",
  },
  detailsFruitX: {
    display: "flex",
    alignItems: "flex-start",
  },
  paddingTop: {
    paddingTop: "14px",
  },
  containerText: {
    fontSize: "1.2rem",
    color: "gray",
  },
  refClass: {
    fontSize: "10px !important",
    fontWeight: "400 !important",
    wordBreak: "break-all",
  },
  fontSize28: {
    fontSize: "28px !important",
    color: `${colors.red} !important`,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: " .2rem",
  },
  outstandingText: {
    display: "flex",
    flexDirection: "column",
  },
  outstandingTextColor: {
    color: `${colors.grayText} !important`,
    fontSize: "28px",
  },
  paymentBorder: {
    borderTop: "1px solid #dbdbdb",
    background: "white",
  },
  calendarBox: {
    margin: ".3rem 0rem",
    left: "0",
    right: "0",
    display: "flex",
    justifyContent: "center",
  },
  myPaymentsBox: {
    overflowY: "auto",
    padding: "0 12px",
    height: "60vh",
    minHeight: "35rem",
    maxHeight: "50rem",
  },
  myBuysBox: {
    overflowY: "auto",
    paddingLeft: "12px",
    paddingRight: "12px",
    height: "60vh",
    minHeight: "35rem",
    maxHeight: "50rem",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 12px",
    border: "1px solid #4CAF50",
    borderRadius: "4px",
    backgroundColor: "#E8F5E9",
    color: "#4CAF50",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "default",
  },
  checkIcon: {
    marginLeft: "4px",
    fontSize: "16px",
    color: "#4CAF50",
  },
}));

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "#F9F9F9",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "16px",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    padding: "10px",
  },
  label: {
    fontSize: "14px",
    color: "#6B6B6B",
  },
  value: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  divider: {
    margin: "0 10px",
    backgroundColor: "#E0E0E0",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  downloadButton: {
    width: "48%",
    backgroundColor: theme.colors.primary,
    color: "#FFF",
    textTransform: "none",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.colors.primary,
    },
    "&:focus": {
      backgroundColor: theme.colors.primary,
    },
    "&:active": {
      backgroundColor: theme.colors.primary,
    },
    fontWeight: "bold",
  },
  signedButton: {
    borderRadius: "8px",
    width: "48%",
    borderColor: "#E8E6E7",
    color: "#6B6B6B",
    textTransform: "none",
    background: "#E8E6E7",
    "&:hover": {
      backgroundColor: "#E8E6E7",
      borderColor: "#E8E6E7",
    },
    "&:focus": {
      backgroundColor: "#E8E6E7",
      borderColor: "#E8E6E7",
    },
    "&:active": {
      backgroundColor: "#E8E6E7",
      borderColor: "#E8E6E7",
    },
    fontWeight: "bold",
  },
  eSignButton: {
    backgroundColor: "#d97742",
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    border: "none",
    borderRadius: "12px",
    padding: "10px 20px",
    width: "160px",
    height: "46px",
    cursor: "pointer",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#d97742",
    },
    "&:focus": {
      backgroundColor: "#d97742",
    },
    "&:active": {
      backgroundColor: "#d97742",
    },
  },
  scrollableContent: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 12.3rem)",
    backgroundColor: "#F9F9F9",
  },
  eSignButtonFooter: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    textAlign: "center",
    padding: "2rem",
    backgroundColor: theme.colors.white,
  },
  eSigCardButton: {
    backgroundColor: "#d97742",
    color: "white",
    fontSize: "10px",
    fontWeight: "bold",
    border: "none",
    borderRadius: "8px",
    textTransform: "capitalize",
    marginTop: ".5rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#d97742",
    },
    "&:focus": {
      backgroundColor: "#d97742",
    },
    "&:active": {
      backgroundColor: "#d97742",
    },
  },
  priceTag: {
    transform: "rotate(89deg)",
    fontSize: "14px",
    color: theme.colors.primary,
  },
}));
